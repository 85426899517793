import { createApp } from 'vue';
import LazyComponent from 'v-lazy-component';
import App from './App.vue';
import router from './router';
import store from './store/store';
import axios from 'axios';
import { createI18n } from 'vue-i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import 'vue3-carousel/dist/carousel.css';
import './assets/main.css';
import './assets/grid.css';

library.add(fas, fab, far);

const i18n = createI18n({
  locale: ''
});

fetch('https://turkobaba.cekirdekarge.com/media/language.json')
  .then(response => response.json())
  .then(data => {
    i18n.global.setLocaleMessage('tr', data.tr);
    i18n.global.setLocaleMessage('ar', data.ar);
    i18n.global.setLocaleMessage('en', data.en);
    i18n.global.setLocaleMessage('rus', data.rus);

    i18n.global.locale = 'tr';
  })
  .catch(error => {
    console.error(error);
  });

createApp(App)
  .use(store)
  .use(router)
  .use(LazyComponent)
  .use(i18n)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mixin({
    beforeMount() {
      this.$http = axios;
    }
  })
  .mount('#app');