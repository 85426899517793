<template>
  <div class="navbar" id="navbar">
    <nav :class="[{ onScroll: !view.topOfPage }, navbarClass]">
      <input
        class="menu-btn"
        type="checkbox"
        id="menu-btn"
        v-model="menuOpen"
      />
      <label class="menu-icon" for="menu-btn">
        <span></span>
      </label>
      <div class="logo">
        <router-link :to="{ name: 'home' }"
          ><img src="@/assets/logo.png"
        /></router-link>
      </div>
      <ul
        class="menu"
        :class="{ 'submenu-open': submenuOpen, 'menu-open': menuOpen }"
      >
        <div class="left-menu">
          <li @click="closeMenu" class="extra-menu">
            <router-link :to="{ name: 'home' }">{{
              $t("top_navigation_bar.homepage")
            }}</router-link>
          </li>
          <li
            class="extra-menu"
            @mouseenter="openSubmenu('kurumsal')"
            @mouseleave="closeSubmenu('kurumsal')"
          >
            <router-link :to="{ name: '' }" @click="toggleSubmenu('kurumsal')"
              >{{ $t("top_navigation_bar.institutional")
              }}<font-awesome-icon
                class="icon"
                :icon="['fas', 'chevron-right']"
            /></router-link>
            <ul v-show="submenuOpen.includes('kurumsal')">
              <li @click="closeMenu">
                <router-link :to="{ name: 'aboutUs' }">{{
                  $t("top_navigation_bar.institutional_about")
                }}</router-link>
              </li>
              <li @click="closeMenu">
                <router-link :to="{ name: 'vision' }">{{
                  $t("top_navigation_bar.institutional_vision")
                }}</router-link>
              </li>
              <!-- <li @click="closeMenu"><router-link :to="{name:'ourQuality'}">{{ $t('top_navigation_bar.institutional_quality') }}</router-link></li> -->
              <li @click="closeMenu">
                <router-link :to="{ name: 'media' }">{{
                  $t("top_navigation_bar.institutional_media")
                }}</router-link>
              </li>
            </ul>
          </li>
          <li
            class="extra-menu"
            id="extra-products-menu"
            @mouseenter="openSubmenu('urunler')"
            @mouseleave="closeSubmenu('urunler')"
          >
            <router-link :to="{ name: '' }" @click="toggleSubmenu('urunler')"
              >{{ $t("top_navigation_bar.products")
              }}<font-awesome-icon
                class="icon"
                :icon="['fas', 'chevron-right']"
            /></router-link>
            <ul v-show="submenuOpen.includes('urunler')">
              <h4 class="extra-menu-header">ÜRÜN KATEGORİLERİ</h4>
              <li
                @click="closeMenu"
                class="products"
                v-for="item in category_store"
                :key="item.id"
              >
                <router-link :to="{ path: '/urunler/' + item.id }">
                  <img
                    :src="
                      'https://turkobaba.cekirdekarge.com/media/imageCat/' +
                      item.id +
                      '.png'
                    "
                  />
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </li>
        </div>
        <div class="right-menu">
          <li @click="closeMenu" class="extra-menu">
            <router-link :to="{ name: 'production' }">{{
              $t("top_navigation_bar.production")
            }}</router-link>
          </li>
          <li @click="closeMenu" class="extra-menu">
            <a href="/katalog.pdf" target="_blank">{{
              $t("top_navigation_bar.katalog")
            }}</a>
          </li>
          <li @click="closeMenu" class="extra-menu">
            <router-link :to="{ name: 'contact' }">{{
              $t("top_navigation_bar.comminication")
            }}</router-link>
          </li>
        </div>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Navbar",

  data() {
    return {
      view: { topOfPage: true },
      menuOpen: false,
      submenuOpen: [],
      hoverOpen: false,
      isMenuOpen: false
    };
  },

  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },

  computed: {
    ...mapState(["category_store"]),

    navbarClass() {
      return this.$route.name === "home"
        ? "navbar navbar-transparent"
        : "navbar navbar-colored";
    }
  },

  created() {
    this.fetchCategories();
  },

  methods: {
    ...mapActions(["fetchCategories"]),

    handleScroll() {
      if (window.pageYOffset > 300) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },

    openSubmenu(submenu) {
      if (window.innerWidth >= 1100) {
        this.hoverOpen = true;
        this.submenuOpen = [submenu];
      }
    },

    closeSubmenu(submenu) {
      if (window.innerWidth >= 1100) {
        this.hoverOpen = false;
        this.submenuOpen = [];
      }
    },

    closeMenu() {
      this.menuOpen = false;
    },

    toggleSubmenu(submenu) {
      if (this.submenuOpen.includes(submenu)) {
        this.submenuOpen = this.submenuOpen.filter((item) => item !== submenu);
      } else {
        this.submenuOpen.push(submenu);
      }
    }
  }
};
</script>

<style scoped>
.navbar-transparent {
  height: 80px;
  background-image: linear-gradient(
    to top,
    rgba(22, 24, 22, 0),
    rgba(22, 24, 22, 0.2),
    rgba(22, 24, 22, 0.4),
    rgba(22, 24, 22, 0.6),
    rgba(22, 24, 22, 0.8),
    rgb(22, 24, 22, 0.9)
  );
}
.navbar-colored {
  background-color: #639d40;
  height: 79px;
}
.onScroll {
  background-color: #639d40;
  width: 100%;
  height: 79px;
  background-image: none;
}
nav {
  position: fixed;
  z-index: 1;
  width: 100%;
  justify-content: center;
}
nav ul {
  list-style: none;
  overflow: hidden;
}
.logo {
  position: relative;
  padding-right: 20px;
  height: auto;
  width: 150px;
  margin: auto;
  top: -28px;
}
.logo img {
  height: 100%;
  width: 100%;
}
.icon {
  margin-left: 10px;
  margin-bottom: 3px;
  height: 10px;
}
nav li a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #ffff;
}
.navbar-icon {
  position: fixed;
  cursor: pointer;
  justify-content: flex-end !important;
  margin-left: -80px;
  display: flex;
  width: 100%;
  top: 10px;
  right: 20px;
}
.menu {
  height: -webkit-fill-available;
}
.left-menu {
  margin-top: 80px;
}
nav .menu {
  clear: both;
  position: fixed;
  max-height: 0;
  font-weight: 600;
  font-size: 14px;
  background-color: #639d40;
  z-index: -1;
  top: -85px;
}
nav .menu-icon {
  cursor: pointer;
  display: inline-flex;
  float: left;
  padding: 40px 20px;
  position: relative;
  user-select: none;
}
nav .menu-icon span {
  background: #ffff;
  display: block;
  height: 3px;
  position: relative;
  transition: all 0.2s ease-out;
  width: 30px;
}
nav .menu-icon span:before,
nav .menu-icon span:after {
  background: #ffff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}
nav .menu-icon span:before {
  top: 10px;
}
nav .menu-icon span:after {
  top: -10px;
}
nav .menu-btn {
  display: none;
}
nav .menu-btn:checked ~ .menu {
  overflow: scroll;
  width: 100%;
  max-height: -webkit-fill-available;
  top: 0;
}
nav .menu-btn:checked ~ .menu-icon span {
  background: transparent;
}
nav .menu-btn:checked ~ .menu-icon span:before {
  transform: rotate(-45deg);
}
nav .menu-btn:checked ~ .menu-icon span:after {
  transform: rotate(45deg);
}
nav .menu-btn:checked ~ .menu-icon:not(.steps) span:before,
nav .menu-btn:checked ~ .menu-icon:not(.steps) span:after {
  top: 0;
}
.extra-menu ul li a:hover {
  text-shadow: 1px 1px 2px white, 0 0 2px black, 0 0 5px white;
}
.extra-menu-header {
  width: fit-content;
  border-bottom: 1px solid #ffff;
  margin: 0px;
  margin-left: 20px;
  color: #ffff;
}
.products img {
  display: none;
}
.extra-menu {
  position: relative;
  overflow: hidden;
  font-size: 16px;
}
.extra-menu ul {
  display: grid;
}
.extra-menu ul li a {
  font-size: 12px;
  padding: 10px 20px;
}

@media (min-width: 1350px) {
  nav {
    position: fixed;
    width: 100%;
    z-index: 1;
  }
  nav li {
    float: left;
  }
  nav li a {
    padding: 18px 24px;
  }
  nav .menu {
    position: sticky;
    clear: none;
    float: right;
    width: 100%;
    background: none;
    font-weight: 600;
    font-size: 14px;
    z-index: 1;
  }
  nav .menu-icon {
    display: none;
  }
  .left-menu {
    right: 50%;
    left: auto;
    margin: 0;
    top: 17px;
    position: fixed;
    margin-right: 100px;
  }
  .right-menu {
    left: 50%;
    right: auto;
    position: fixed;
    top: 17px;
    margin-left: 100px;
  }
  .logo {
    top: -28px;
    position: relative;
    padding: 0;
    margin: auto;
  }

  .extra-menu {
    position: relative;
    overflow: hidden;
  }
  .extra-menu ul {
    display: grid;
  }
  .extra-menu ul li a {
    width: 130px;
    font-size: 12px;
    color: black;
  }
  #extra-products-menu ul {
    background: #ffff;
    box-shadow: 0px 0px 5px rgba(92, 25, 25, 0.3);
    position: fixed;
    margin-left: -253px;
    width: 660px;
    text-align: center;
    display: inline-table;
  }
  #extra-products-menu ul li a {
    cursor: pointer;
    width: 112px;
    padding: 30px 10px;
    color: black;
  }
  .extra-menu-header {
    width: fit-content;
    margin: 0 30px;
    color: black;
    margin-top: revert;
    padding-left: 0;
    text-align: left;
    border-bottom: 1px solid;
  }
  .products img {
    display: block;
    margin: auto;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50px;
    border: 1px solid rgb(218, 209, 209);
  }
  .extra-menu:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background-color: #639d40;
    transition: width 0.3s ease-in-out;
    z-index: -1;
  }
  .extra-menu:hover:before {
    width: 100%;
  }
  #extra-products-menu ul li a:hover {
    text-shadow: none;
  }
  .menu li ul {
    background-color: #ffff;
    box-shadow: 0px 0px 5px rgba(92, 25, 25, 0.3);
    position: fixed;
  }
  .icon {
    display: none;
  }
}
</style>
