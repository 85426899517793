import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/urunler/:categoryId',
    name: 'products',
    component: () => import("../components/Products.vue"),
  },
  {
    path: '/urunler/:categoryId/:productId',
    name: 'productContent',
    component: () => import("../components/ProductContent.vue"),
    props: true 
  },
  {
    path: '/iletisim',
    name: 'contact',
    component: () => import("../components/Contact.vue"),
  },
  {
    path: '/hakkimizda',
    name: 'aboutUs',
    component: () => import("../components/AboutUs.vue"),
  },
  {
    path: '/vizyon&misyon',
    name: 'vision',
    component: () => import("../components/Vision.vue"),
  },
  {
    path: '/kalite-politikamiz',
    name: 'ourQuality',
    component: () => import("../components/OurQuality.vue"),
  },
  {
    path: '/production',
    name: 'production',
    component: () => import("../components/Production.vue"),
  },
  {
    path: '/privacy-policy-and-terms',
    name: 'kvkk',
    component: () => import("../components/privacyPolicy.vue"),
  },
  {
    path: '/medya',
    name: 'media',
    component: () => import("../components/Media.vue"),
  },
  {
    path: "/:hae?",
    name: "NotFound",
    component: () => import("../components/NotFound.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {top: 0}
  },
})

export default router;
