<template>
  <div
    class="lang-button"
    @mouseover="isMenuOpen = true"
    @mouseleave="isMenuOpen = false"
  >
    <button>
      <li class="selectedLang">{{ getLanguageName(selectedLanguage) }}</li>
      <li><img :src="getImagePath(selectedLanguage)" /></li>
      <li>
        <font-awesome-icon class="icon" :icon="['fas', 'chevron-down']" />
      </li>
    </button>
    <ul class="lang-dropdown" v-show="isMenuOpen">
      <ul @click="changeLanguage('tr')" @mouseover="closeMenu">
        <li class="chooseLang" :class="{ active: selectedLanguage === 'tr' }">
          TR
        </li>
        <li><img :src="getImagePath('tr')" /></li>
      </ul>
      <ul @click="changeLanguage('en')" @mouseover="closeMenu">
        <li class="chooseLang" :class="{ active: selectedLanguage === 'en' }">
          EN
        </li>
        <li><img :src="getImagePath('en')" /></li>
      </ul>
      <ul @click="changeLanguage('ar')" @mouseover="closeMenu">
        <li class="chooseLang" :class="{ active: selectedLanguage === 'ar' }">
          AR
        </li>
        <li><img :src="getImagePath('ar')" /></li>
      </ul>
      <ul @click="changeLanguage('rus')" @mouseover="closeMenu">
        <li class="chooseLang" :class="{ active: selectedLanguage === 'rus' }">
          RUS
        </li>
        <li><img :src="getImagePath('rus')" /></li>
      </ul>
    </ul>
  </div>
</template>

<script>
export default {
  name: "language",

  data() {
    return {
      selectedLanguage: "tr",
      isMenuOpen: false
    };
  },

  mounted() {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
      this.fetchData().then(() => {
        this.$i18n.locale = this.selectedLanguage;
      });
    } else {
      this.fetchData("tr").then(() => {
        this.$i18n.locale = this.selectedLanguage;
      });
    }
  },

  methods: {
    closeMenu() {
      this.isMenuOpen = false;
    },

    changeLanguage(language) {
      this.selectedLanguage = language;
      localStorage.setItem("selectedLanguage", this.selectedLanguage);
      this.fetchData().then(() => {
        this.$i18n.locale = this.selectedLanguage;
      });
    },

    getLanguageName(language) {
      if (language === "en") {
        return "EN";
      } else if (language === "ar") {
        return "AR";
      } else if (language === "rus") {
        return "RUS";
      } else if (language === "tr") {
        return "TR";
      }
    },

    getImagePath(language) {
      return require(`@/assets/${language.toLowerCase()}.png`);
    },

    fetchData() {
      const apiUrl =
        "https://turkobaba.cekirdekarge.com/media/language.json?nocache=" +
        new Date().getTime();

      return fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          this.responseData = data[this.selectedLanguage];
          this.$i18n.setLocaleMessage(
            this.selectedLanguage,
            data[this.selectedLanguage]
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
};
</script>

<style scoped>
img {
  height: 20px;
  width: 21px;
}
li {
  display: inline-block;
  padding: 5px;
}
.selectedLang,
.chooseLang {
  font-size: 18px;
  font-weight: 600;
}
.icon {
  height: 17px;
}
.lang-button {
  position: fixed;
  float: right;
  right: 10px;
  top: 15px;
  z-index: 9;
}
.lang-button button {
  padding: 10px;
  display: flex;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: none;
  color: #ffff;
}

.lang-button:hover button {
  background: #ffff;
  color: black;
}
.lang-dropdown {
  background: #ffff;
  padding: 11px;
  cursor: pointer;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  top: -10px;
}
.lang-dropdown ul {
  display: flex;
  justify-content: space-between;
  width: auto;
}
.lang-dropdown li {
  display: block;
  padding: 5px;
}
.lang-dropdown ul:hover {
  background-color: #f5f5f5;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  img {
    width: 20px;
    height: 20px;
  }
  .selectedLang,
  .chooseLang {
    font-size: 18px;
  }
  .icon {
    height: 16px;
  }
}
</style>
