<template>
  <div class="home">
    <div
      v-if="slider === 'image'"
      class="container-image"
      :style="backgroundImageStyle"
      ref="container"
    ></div>
    <div v-else class="container">
      <video
        playsinline
        autoplay
        muted
        loop
        preload="auto"
        ref="myVideo"
        :src="`${'https://turkobaba.cekirdekarge.com' + video} `"
      ></video>
    </div>
    <div
      class="section5"
      ref="section5"
      :class="{ show: showSection5 }"
      v-if="favorites"
    >
      <div class="row">
        <h2 class="brushstroke">{{ $t("homePage.best_seller") }}</h2>
        <div
          v-for="(favorite, index) in favorites"
          :key="index"
          class="col-md-2"
          @click="goToProduct(favorite)"
        >
          <img
            :src="
              'https://turkobaba.cekirdekarge.com/media/imageProd_orj/' +
              favorite.id +
              '.png'
            "
          />
          <h3>{{ formatProductName(favorite.urun_isim) }}</h3>
        </div>
      </div>
    </div>

    <div class="section2" ref="section2" :class="{ show: showSection2 }">
      <div class="content">
        <div class="content-text">
          <h3>{{ $t("homePage.statement_header") }}</h3>
          <div>
            {{ $t("homePage.statement_content") }}
          </div>
        </div>
        <div
          class="content-img"
          :style="{
            'background-image': `url(${
              'https://turkobaba.cekirdekarge.com' + images.turkobaba_defination
            })`
          }"
        ></div>
      </div>
    </div>
    <div class="section3">
      <div class="carousel-section">
        <div class="section3-text">
          <div>{{ $t("homePage.products_list_header") }}</div>
        </div>
        <div class="slide-carousel">
          <Carousel
            class="carousel"
            :items-to-show="visibleItems"
            :wrap-around="true"
          >
            <Slide v-for="item in category_store" :key="item.id">
              <router-link :to="{ path: '/urunler/' + item.id }">
                <div class="slide-image">
                  <img
                    :src="
                      'https://turkobaba.cekirdekarge.com/media/imageCat/' +
                      item.id +
                      '.png'
                    "
                  />
                  <h3>{{ item.name }}</h3>
                </div>
              </router-link>
            </Slide>
            <template #addons class="addons">
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
    <div class="section4" ref="section4" :class="{ show: showSection4 }">
      <div>
        <strong class="counter" :data-to="targetValue1" data-append="+"
          >{{ counterValue1 }} +
        </strong>
        <p>{{ $t("homePage.counter_defination_1") }}</p>
      </div>
      <div>
        <strong class="counter" :data-to="targetValue2" data-append="+"
          >{{ counterValue2 }} +
        </strong>
        <p>{{ $t("homePage.counter_defination_2") }}</p>
      </div>
      <div>
        <strong class="counter" :data-to="targetValue3" data-append="+"
          >{{ counterValue3 }} +
        </strong>
        <p>{{ $t("homePage.counter_defination_3") }}</p>
      </div>
      <div>
        <strong class="counter" :data-to="targetValue4" data-append="+"
          >{{ counterValue4 }} +</strong
        >
        <p>{{ $t("homePage.counter_defination_4") }}</p>
      </div>
    </div>
    <div class="section1" ref="section1" :class="{ show: showSection1 }">
      <div class="top-text">
        <div class="text">
          <div
            class="img-container"
            :style="{
              'background-image': `url(${
                'https://turkobaba.cekirdekarge.com' +
                images['4_part_defination_1']
              })`
            }"
          ></div>
          <h3>{{ $t("homePage.feature_header_1") }}</h3>
          <div>{{ $t("homePage.feature_content_1") }}</div>
        </div>
        <div class="text">
          <div
            class="img-container"
            :style="{
              'background-image': `url(${
                'https://turkobaba.cekirdekarge.com' +
                images['4_part_defination_2']
              })`
            }"
          ></div>
          <h3>{{ $t("homePage.feature_header_2") }}</h3>
          <div>{{ $t("homePage.feature_content_2") }}</div>
        </div>
      </div>
      <div class="bottom-text">
        <div class="text">
          <div
            class="img-container"
            :style="{
              'background-image': `url(${
                'https://turkobaba.cekirdekarge.com' +
                images['4_part_defination_3']
              })`
            }"
          ></div>
          <h3>{{ $t("homePage.feature_header_3") }}</h3>
          <div>{{ $t("homePage.feature_content_3") }}</div>
        </div>
        <div class="text">
          <div
            class="img-container"
            :style="{
              'background-image': `url(${
                'https://turkobaba.cekirdekarge.com' +
                images['4_part_defination_4']
              })`
            }"
          ></div>
          <h3>{{ $t("homePage.feature_header_4") }}</h3>
          <div>{{ $t("homePage.feature_content_4") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  components: {
    Carousel,
    Slide,
    Navigation
  },

  data() {
    return {
      counter: {},
      showSection1: false,
      showSection2: false,
      showSection4: false,
      showSection5: false,
      targetValue1: 0,
      targetValue2: 0,
      targetValue3: 0,
      targetValue4: 0,
      counterValue1: 0,
      counterValue2: 0,
      counterValue3: 0,
      counterValue4: 0,
      transitionDuration: 2000,
      visibleItems: 1
    };
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  async mounted() {
    this.fetchData();
    this.startImageRotation();
    const observerOptions = {
      rootMargin: "0px",
      threshold: 0.5
    };

    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    const section1Observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            this.showSection1 = true;
          }, 100);
        }
      });
    }, observerOptions);
    section1Observer.observe(this.$refs.section1);

    const section2Observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            this.showSection2 = true;
          }, 300);
        }
      });
    }, observerOptions);
    section2Observer.observe(this.$refs.section2);

    const section4Observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            this.showSection4 = true;
          }, 100);
          this.startCounters();
        }
      });
    }, observerOptions);
    section4Observer.observe(this.$refs.section4);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },

  computed: {
    ...mapState([
      "product_store",
      "category_store",
      "selectedProduct",
      "favorites",
      "sliderImages",
      "activeImage",
      "images",
      "video",
      "slider"
    ]),

    backgroundImageStyle() {
      return {
        "background-image": `url(https://turkobaba.cekirdekarge.com${this.activeImage})`
      };
    }
  },

  methods: {
    ...mapActions([
      "fetchProduct",
      "fetchCategories",
      "fetchProductContent",
      "fetchData"
    ]),

    startImageRotation() {
      this.timer = setInterval(() => {
        if (this.slider === "image") {
          const container = this.$refs.container;
          if (container) {
            container.style.transition = `opacity ${
              this.transitionDuration / 2
            }ms`;
            container.style.opacity = 0.5;

            setTimeout(() => {
              container.style.opacity = 1;
            }, this.transitionDuration / 2);
          }
        } else if (this.slider === "video") {
          return;
        }
      }, 7000);
    },

    formatProductName(name) {
      if (!name) return "";

      const formattedName =
        name.charAt(0).toLocaleUpperCase("tr") +
        name.slice(1).toLocaleLowerCase("tr");
      return formattedName;
    },

    goToProduct(favorite) {
      this.$router.push({
        name: "productContent",
        params: { categoryId: 3, productId: favorite.id }
      });
      this.fetchProductContent(favorite.id);
    },

    startCounters() {
      this.increaseCounter1();
      this.increaseCounter2();
      this.increaseCounter3();
      this.increaseCounter4();
    },

    increaseCounter1() {
      this.targetValue1 = Number(this.$t("homePage.counter_header_1"));
      if (this.counterValue1 >= this.targetValue1) return;

      this.counterValue1++;
      setTimeout(this.increaseCounter1, 50);
    },

    increaseCounter2() {
      this.targetValue2 = Number(this.$t("homePage.counter_header_2"));
      if (this.counterValue2 >= this.targetValue2) return;

      this.counterValue2++;
      setTimeout(this.increaseCounter2, 50);
    },

    increaseCounter3() {
      this.targetValue3 = Number(this.$t("homePage.counter_header_3"));
      if (this.counterValue3 >= this.targetValue3) return;

      this.counterValue3++;
      setTimeout(this.increaseCounter3, 5);
    },

    increaseCounter4() {
      this.targetValue4 = Number(this.$t("homePage.counter_header_4"));
      if (this.counterValue4 >= this.targetValue4) return;

      this.counterValue4++;
      setTimeout(this.increaseCounter4, 5);
    },

    handleResize() {
      if (window.innerWidth < 1300) {
        this.visibleItems = 1;
      } else if (window.innerWidth < 1800) {
        this.visibleItems = 3;
      } else if (window.innerWidth < 2500) {
        this.visibleItems = 5;
      } else {
        this.visibleItems = 7;
      }
    }
  }
};
</script>

<style scoped>
.section1,
.section2,
.section4 {
  opacity: 0;
  transform: scale(0);
  transition: opacity 1s, transform 1s;
}
.show {
  opacity: 1;
  transform: scale(1);
}
.container-image {
  height: 100vh;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.container video {
  width: 100%;
  height: 100%;
}
.section1 {
  display: flex;
  justify-content: center;
  padding: 50px;
  width: auto;
  margin: auto;
}
.top-text,
.bottom-text {
  display: flex;
  justify-content: center;
}
.top-text div,
.bottom-text div {
  padding: 15px;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 2;
}
.text div {
  width: 260px;
}
h3 {
  font-size: 12px;
  color: #639d40;
}
.text .img-container {
  height: 100px;
  width: 100px;
  cursor: pointer;
  background-size: cover;
  margin: auto;
}
.section2 {
  padding: 50px;
}
.section2 .content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.section2 .content-img {
  background-size: cover;
  background-position: center;
  width: 500px;
  height: 400px;
}
.section2 .content-text {
  line-height: 1.8;
  font-size: 18px;
  width: 720px;
}
.content-text div {
  white-space: pre-line;
}
.section2 .content-text h3 {
  padding-bottom: 30px;
  font-size: 55px;
}
.section3 {
  margin: 100px 0;
}
.section3 .carousel-section {
  background-color: rgba(128, 128, 128, 0.363);
  display: flex;
}
.section3 .slide-carousel {
  margin: auto;
  width: 75%;
  padding: 50px 0;
}
.section3 .section3-text {
  background-image: url("/image.jpg");
  background-color: rgba(80, 8, 8, 0.7);
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  display: flex;
}
.section3 .section3-text div {
  margin: auto;
  text-align: center;
  color: #ffff;
  font-size: 20px;
  padding: 18px;
}
.carousel {
  width: auto;
}
.section3 .slide-image img {
  width: 100%;
  height: 100%;
}
.slide-image h3 {
  width: -webkit-fill-available;
  position: fixed;
  text-align: center;
  margin-top: -137px;
  color: black;
  display: none;
}
.slide-image:hover h3 {
  display: block;
}
.section4 {
  display: flex;
  padding-top: 50px;
  padding-bottom: 65px;
  margin-top: 50px;
  background: #f8f8f8;
  text-align: center;
  justify-content: center;
}
.section4 div {
  padding: 0 50px;
}
.section4 .counter {
  color: #ed1c24;
  font-weight: 900;
  font-size: 90px;
}
.section5 {
  padding: 100px 0;
  width: 1430px;
  margin: auto;
}
.section5 img {
  width: 100%;
  height: auto;
  object-fit: fill;
}
.section5 h3 {
  padding: 5px;
}
.section5 .brushstroke {
  position: relative;
  padding: 20px;
  width: fit-content;
  margin: 0 auto;
  font-size: 55px;
  color: #639d40;
}
.col-md-2 {
  width: 15.2%;
  position: relative;
  overflow: hidden;
}
.col-md-2:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.6;
  transition: left 0.5s ease-out;
}
.col-md-2:hover:after {
  left: 0;
}
.col-md-2:hover {
  box-shadow: 0 0 10px rgba(218, 201, 201, 0.5);
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.5s ease-out, left 0.5s ease-out, opacity 0.5s ease-out;
}

@media (max-width: 1450px) {
  .section5 {
    width: 900px;
  }
  .container video {
    width: 100%;
  }
  .col-md-2 {
    min-width: 22.6% !important;
    float: left !important;
    margin: 30px 10px !important;
  }
}
@media (max-width: 1300px) {
  .section1 {
    display: block;
  }
  .text div {
    width: 350px;
    line-height: 1.5;
    padding: 10px;
  }
  .section2 .content-text {
    line-height: 2;
    font-size: 16px;
    width: 400px;
  }
  .section2 .content-img {
    background-size: cover;
    background-position: center;
    width: 400px;
    height: 400px;
  }
  .section3 .carousel-section {
    display: block;
  }
  .section3 .slide-carousel {
    width: 350px;
  }
}

@media (max-width: 1200px) {
  .container video {
    width: 100%;
    padding-top: 79px;
  }
  .section4 div {
    padding: 0 50px;
  }
  .section4 .counter {
    color: #ed1c24;
    font-weight: 900;
    font-size: 50px;
  }
}

@media (max-width: 900px) {
  .section1 {
    display: block;
    padding: 0px;
  }
  .top-text,
  .bottom-text {
    display: block;
  }
  .top-text div,
  .bottom-text div {
    margin: auto;
  }
  .section2 .content {
    display: block;
    margin: 0 auto;
  }
  .section2 .content-text {
    width: auto;
  }
  .section2 .content-img {
    margin: auto;
    width: 400px;
    height: 300px;
  }
  .text div,
  .text div {
    width: auto;
  }
  .section4 div {
    padding: 0 20px;
  }
  .section5 {
    width: 600px;
  }
  .col-md-3 h3 {
    position: relative;
    top: -165px;
    color: black;
    display: none;
  }
  .col-md-3:hover h3 {
    display: block;
  }
  .col-md-2 {
    min-width: 30% !important;
  }
  .col-md-2 h3 {
    font-size: 10px !important;
  }
}

@media (max-width: 600px) {
  .section5 {
    width: 360px;
  }
  .col-md-2 {
    min-width: 44.4% !important;
  }
}

@media (max-width: 500px) {
  .section2 {
    padding: 50px 20px;
  }
  .section2 .content-img {
    width: 300px;
    height: 250px;
  }
  .section4 div {
    padding: 0 10px;
    font-size: 12px;
  }
  .section4 .counter {
    color: #ed1c24;
    font-weight: 900;
  }
}
</style>
