<template>
<div class="home">
    <Navbar />
    <Language />
    <router-view />
    <ChatNow />
    <Footer />
 </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import ChatNow from './components/ChatNow.vue';
import Language from './components/Language.vue';

export default {
  name: "App",

  components: { Navbar, Footer, ChatNow, Language },
  
};
</script>

