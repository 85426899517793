<template>
  <footer>
    <div class="footer-note">
      <div class="contents">
        <div class="footer-header">
          <li class="logo"><img src="@/assets/logo.png" /></li>
          <ul class="icon-section">
            <li>
              <a
                class="icon"
                href="https://www.facebook.com/turkobabadelight/"
                target="_blank"
                ><font-awesome-icon :icon="['fab', 'facebook-f']"
              /></a>
            </li>
            <li>
              <a
                class="icon"
                href="https://www.instagram.com/turkobabaofficial/"
                target="_blank"
                ><font-awesome-icon :icon="['fab', 'instagram']"
              /></a>
            </li>
          </ul>
        </div>
        <div class="footer-header">
          <li><h3>TURKOBABA</h3></li>
          <li>
            <router-link :to="{ name: 'aboutUs' }">{{
              $t("top_navigation_bar.institutional_about")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'vision' }">{{
              $t("top_navigation_bar.institutional_vision")
            }}</router-link>
          </li>
          <li>
            <a href="/katalog.pdf" target="_blank">{{
              $t("top_navigation_bar.katalog")
            }}</a>
          </li>
          <li>
            <router-link :to="{ name: 'production' }">{{
              $t("top_navigation_bar.production")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'kvkk' }">{{
              $t("top_navigation_bar.kvkk")
            }}</router-link>
          </li>
        </div>
        <div class="footer-header">
          <li>
            <h3>{{ $t("top_navigation_bar.comminication") }}</h3>
          </li>
          <div>
            <li>
              <font-awesome-icon
                :icon="['fasr', 'location-dot']"
                style="padding-right: 10px"
              />
              {{ $t("communication.adress") }}
            </li>
            <li>
              <font-awesome-icon
                :icon="['fasr', 'phone']"
                style="padding-right: 10px"
              />
              {{ $t("communication.phone") }}
            </li>
            <li>
              <font-awesome-icon
                :icon="['fab', 'whatsapp']"
                style="padding-right: 10px"
              />
              {{ $t("communication.whatsapp") }}
            </li>
            <li>
              <a href="mailto:bilgi@turkobaba.com.tr">
                <font-awesome-icon
                  :icon="['fasr', 'paper-plane']"
                  style="padding-right: 10px"
                />
                bilgi@turkobaba.com.tr
              </a>
            </li>
          </div>
        </div>
        <div class="footer-header" style="padding-left: 0">
          <li>
            <iframe
              width="100%"
              height="200px"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=tr&amp;q=%20Kocasinan%20Merkez%20Mahallesi%20Ahlat%20Sokak%20No%2013%20Bahcelievler%20/%20Istanbul+(Turko%20Baba)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.maps.ie/population/">>area maps</a>
            </iframe>
          </li>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
footer {
  position: absolute;
  width: 100%;
  background: #639d40;
}
.footer-note {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.footer-header {
  display: flex;
  flex-direction: column;
  padding: 40px;
  color: #ffff;
}
.footer-header ul {
  display: flex;
  position: absolute;
  top: 220px;
}
.footer-header .icon-section {
  margin-left: 30px;
}
.icon {
  margin: 25px;
}
.logo {
  position: relative;
  width: 190px;
  margin: auto;
}
.logo img {
  height: 100%;
  width: 100%;
}
.footer-header a,
.footer-header li {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  list-style: none;
  color: #ffff;
}
.footer-header li:hover {
  cursor: pointer;
  text-shadow: 1px 1px 2px black, 0 0 25px white, 0 0 5px white;
}
.contents {
  display: flex;
  margin: auto;
}
.map {
  height: 200px;
}
@media (max-width: 1250px) {
  .footer-header {
    padding: 40px 30px;
  }
}
@media (max-width: 1000px) {
  .footer-header {
    padding: 40px 20px !important;
  }
  .contents {
    display: grid;
    padding-right: -200px;
    margin: 0;
  }
  .logo {
    margin: 0 auto;
    text-align: center;
  }
  .footer-header ul {
    margin: 0 auto;
    position: inherit;
    margin-top: -70px;
  }
  .footer-header .icon-section {
    margin-left: auto;
  }
  .footer-header {
    float: left;
    padding: 30px 40px;
  }
}
</style>
