<template>
  <div class="container" @click="openWhatsApp">
    <div class="icon-section">
      <font-awesome-icon class="icon" :icon="['fab', 'whatsapp']" />
    </div>
    <div class="liveContact">Whatsapp</div>
  </div>
</template>

<script>
export default {
  name: "chatnow",

  methods: {
    openWhatsApp() {
      var phoneNumber = "5538674924";
      var message = "";

      window.open(
        "https://api.whatsapp.com/send?phone=" +
          phoneNumber +
          "&text=" +
          encodeURIComponent(message)
      );
    }
  }
};
</script>

<style scoped>
.container {
  cursor: pointer;
  position: fixed;
  display: flex;
  float: right;
  right: 10px;
  bottom: 10px;
  z-index: 1;
}

.icon-section {
  box-shadow: 0 0 10px rgba(39, 38, 38, 0.5);
  background-color: #ffff;
  border: 7px solid #639d40;
  border-radius: 50px;
  z-index: inherit;
  bottom: 10px;
  margin-right: -15px;
}
.icon {
  height: 20px;
  width: 20px;
  padding: 10px;
  color: #639d40;
}
.liveContact {
  width: 100px;
  text-align: center;
  padding: 13px 10px;
  margin: auto;
  background: #639d40;
  box-shadow: 0 0 10px rgba(194, 190, 190, 0.5);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  color: #ffff;
}
.container:hover {
  opacity: 0.5;
}
</style>
