import Vuex from 'vuex';
import router from '@/router';
import axios from 'axios';

const API_URL = 'https://turkobaba-api.cekirdekarge.com/web';

export default new Vuex.Store({

  state: {
    product_store: [],
    category_store: [],
    selectedProduct: [],
    favorites: [],
    images: [],
    sliderImages: [],
    activeImage: '',
    video: [],
    media: []
  },

  mutations: {
    SET_PRODUCTS(state, product_store) {
      state.product_store = product_store;
    },

    SET_CATEGORIES(state, category_store) {
      state.category_store = category_store;
    },

    SET_PRODUCT_CONTENT(state, payload) {
      state.selectedProduct = payload;
    },

    SET_IMAGES(state, images) {
      state.images = images;
    },

    SET_FAVORITES(state, favorites) {
      state.favorites = favorites;
    },

    SET_SLIDER_IMAGES(state, images) {
      state.sliderImages = images;
    },

    SET_ACTIVE_IMAGE(state, image) {
      state.activeImage = image;
    },
    SET_SLIDER_VIDEO(state, video) {
      state.video = video;
    },
    SET_SLIDER(state, slider) {
      state.slider = slider;
    },
    SET_MEDIA(state, instagramMedia) {
      state.instagramMedia = instagramMedia
    }
  },

  actions: {
    async fetchProduct({ commit }, categoryId) {
      try {
        const response = await axios.get(`${API_URL}/products/${categoryId}`);
        commit('SET_PRODUCTS', response.data.data);

      } catch (error) {
        router.push({ name: "NotFound" });
      }
    },

    async fetchCategories({ commit }) {
      try {
        const response = await axios.get(`${API_URL}/categories`);
        const categories = response.data.data;
        const filteredCategories = categories.filter((category) => category.id !== 1);

        commit('SET_CATEGORIES', filteredCategories);

      } catch (error) {
        console.log(error)
      }
    },

    async fetchProductContent({ commit }, productId) {
      try {
        const response = await axios.get(`${API_URL}/product/${productId}`);
        commit('SET_PRODUCT_CONTENT', response.data.data);

      } catch (error) {
        console.error(error);
        router.push({ name: "NotFound" });
      }
    },

    async fetchData({ commit }) {
      try {
        const response = await axios.get('https://turkobaba.cekirdekarge.com/media/website-conf.json?nocache=' + (new Date()).getTime());
        commit('SET_SLIDER', response.data.slider);
        commit('SET_IMAGES', response.data.images);
        commit('SET_FAVORITES', response.data.favorites);

        if (response.data.slider === 'image') {
          commit('SET_SLIDER_IMAGES', response.data.images.slider);
          commit('SET_ACTIVE_IMAGE', response.data.images.slider[0]);

          setInterval(() => {
            const currentImageIndex = this.state.sliderImages.indexOf(this.state.activeImage);
            const nextImageIndex = (currentImageIndex + 1) % this.state.sliderImages.length;

            commit('SET_ACTIVE_IMAGE', this.state.sliderImages[nextImageIndex]);
          }, 7000);
        }
        else if (response.data.slider === 'video') {
          commit('SET_SLIDER_VIDEO', response.data.images.slider_video);
        }

      } catch (error) {
        console.error(error);
      }
    },

    async fetchMedia({ commit }) {
      try {
        const response = await axios.get('https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type&access_token=IGQWRQNGJDRjhBNF9MLURPYzBfY0FIellfYU9QcjVaTzctN1pFUFdmOTJFakg3QTVyeE1TU3dtdUhjdG1uN2pqM1gzWm91MjlkYTB4TnVJRVh1bUNiTFVpak9WTUlkcWxNUk5ONEFiZAFp0endxbC03SHNIUU9VUHcZD&limit=20');
        commit('SET_MEDIA', response.data.data);

      } catch (error) {
        console.error(error);
      }
    }
  },
});


